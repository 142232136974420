export class Permiso {
    permisoId: number;
    nombre: string;
    descripcion: string;
}

export class PermRep {
    operation: string;
    categoria: string;
    subcategoria: string;
}