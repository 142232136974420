export class Rol {
    rolId: number;
    nombre: string;
    descripcion: string;
    permisoId: any;
}

export enum Role {
    User = 'User',
    Admin = 'administrador'
}