import {Component, OnInit} from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';

declare const $: any;

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard',
  },
  {
    path: '/administracion',
    title: 'Administración',
    type: 'sub',
    icontype: 'apps',
    collapse: 'administracion',
    children: [
      /*{ path: 'usuario', title: 'Usuario', ab: 'U' },
      { path: 'rol', title: 'Rol', ab: 'R' },
      { path: 'permiso', title: 'Permiso', ab: 'PE' },  
      { path: 'empresa', title: 'Empresa', ab: 'E' },
      { path: 'sucursal', title: 'Sucursal', ab: 'S' },
      { path: 'producto', title: 'Producto', ab: 'P' },
      { path: 'venta', title: 'Venta', ab: 'V' },
      { path: 'cliente', title: 'Cliente', ab: 'C' },
      { path: 'vendedor', title: 'Vendedor', ab: 'VR' },      
      { path: 'factura', title: 'Factura', ab: 'F' },
      { path: 'factura-detalle', title: 'Factura-Detalle', ab: 'FD' },
      { path: 'deuda', title: 'Deuda', ab: 'D' },
      { path: 'servicio', title: 'Servicio', ab: 'SE' },
      { path: 'descuento', title: 'Descuento', ab: 'DE' },*/
      
    ],
  },
  /* {
    path: '/components',
    title: 'Components',
    type: 'sub',
    icontype: 'apps',
    collapse: 'components',
    children: [
      { path: 'buttons', title: 'Buttons', ab: 'B' },
      { path: 'grid', title: 'Grid System', ab: 'GS' },
      { path: 'panels', title: 'Panels', ab: 'P' },
      { path: 'sweet-alert', title: 'Sweet Alert', ab: 'SA' },
      { path: 'notifications', title: 'Notifications', ab: 'N' },
      { path: 'icons', title: 'Icons', ab: 'I' },
      { path: 'typography', title: 'Typography', ab: 'T' },
    ],
  },
  {
    path: '/forms',
    title: 'Forms',
    type: 'sub',
    icontype: 'content_paste',
    collapse: 'forms',
    children: [
      { path: 'regular', title: 'Regular Forms', ab: 'RF' },
      { path: 'extended', title: 'Extended Forms', ab: 'EF' },
      { path: 'validation', title: 'Validation Forms', ab: 'VF' },
      { path: 'wizard', title: 'Wizard', ab: 'W' },
    ],
  },
  {
    path: '/tables',
    title: 'Tables',
    type: 'sub',
    icontype: 'grid_on',
    collapse: 'tables',
    children: [
      { path: 'regular', title: 'Regular Tables', ab: 'RT' },
      { path: 'extended', title: 'Extended Tables', ab: 'ET' },
      { path: 'datatables.net', title: 'Datatables.net', ab: 'DT' },
    ],
  },
  {
    path: '/maps',
    title: 'Maps',
    type: 'sub',
    icontype: 'place',
    collapse: 'maps',
    children: [
      { path: 'google', title: 'Google Maps', ab: 'GM' },
      { path: 'fullscreen', title: 'Full Screen Map', ab: 'FSM' },
      { path: 'vector', title: 'Vector Map', ab: 'VM' },
    ],
  },
  {
    path: '/widgets',
    title: 'Widgets',
    type: 'link',
    icontype: 'widgets',
  },
  {
    path: '/charts',
    title: 'Charts',
    type: 'link',
    icontype: 'timeline',
  },
  {
    path: '/calendar',
    title: 'Calendar',
    type: 'link',
    icontype: 'date_range',
  },
  {
    path: '/pages',
    title: 'Pages',
    type: 'sub',
    icontype: 'image',
    collapse: 'pages',
    children: [
      { path: 'pricing', title: 'Pricing', ab: 'P' },
      { path: 'timeline', title: 'Timeline Page', ab: 'TP' },
      { path: 'login', title: 'Login Page', ab: 'LP' },
      { path: 'register', title: 'Register Page', ab: 'RP' },
      { path: 'lock', title: 'Lock Screen Page', ab: 'LSP' },
      { path: 'user', title: 'User Page', ab: 'UP' },
    ],
  }, */
];
@Component({
  selector: 'app-sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  ps: any;

  constructor(private authService: AuthService, private router: Router) {}

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    
    this.menuItems[1].children = [];
    if(this.authService.hasPermisos({categoria:"USUARIO"}))
      this.menuItems[1].children.push({ path: 'usuario', title: 'Usuario', ab: 'U' });
    if(this.authService.hasPermisos({categoria:"ROL"}))
      this.menuItems[1].children.push({ path: 'rol', title: 'Rol', ab: 'R' });
    if(this.authService.hasPermisos({categoria:"PERMISO"}))
      this.menuItems[1].children.push({ path: 'permiso', title: 'Permiso', ab: 'PE' });
    if(this.authService.hasPermisos({categoria:"EMPRESA"}))
      this.menuItems[1].children.push({ path: 'empresa', title: 'Empresa', ab: 'E' });
    if(this.authService.hasPermisos({categoria:"SUCURSAL"}))
      this.menuItems[1].children.push({ path: 'sucursal', title: 'Sucursal', ab: 'S' });
    if(this.authService.hasPermisos({categoria:"PRODUCTO"}))
      this.menuItems[1].children.push({ path: 'producto', title: 'Producto', ab: 'P' });
    if(this.authService.hasPermisos({categoria:"VENTA"}))
      this.menuItems[1].children.push({ path: 'venta', title: 'Venta', ab: 'V' });
    if(this.authService.hasPermisos({categoria:"CLIENTE"}))
      this.menuItems[1].children.push({ path: 'cliente', title: 'Cliente', ab: 'C' });
    if(this.authService.hasPermisos({categoria:"VENDEDOR"}))
      this.menuItems[1].children.push({ path: 'vendedor', title: 'Vendedor', ab: 'VR' });
    if(this.authService.hasPermisos({categoria:"FACTURA"}))
      this.menuItems[1].children.push({ path: 'factura', title: 'Factura', ab: 'F' });
    if(this.authService.hasPermisos({categoria:"DETALLEFACTURA"}))
      this.menuItems[1].children.push({ path: 'factura-detalle', title: 'Factura-Detalle', ab: 'FD' });
    if(this.authService.hasPermisos({categoria:"DEUDA"}))
      this.menuItems[1].children.push({ path: 'deuda', title: 'Deuda', ab: 'D' });
    if(this.authService.hasPermisos({categoria:"DETALLEDEUDA"}))
      this.menuItems[1].children.push({ path: 'deuda-detalle', title: 'Deuda-Detalle', ab: 'DD' });
    if(this.authService.hasPermisos({categoria:"SERVICIO"}))
      this.menuItems[1].children.push({ path: 'servicio', title: 'Servicio', ab: 'SE' });
    if(this.authService.hasPermisos({categoria:"DESCUENTO"}))
      this.menuItems[1].children.push({ path: 'descuento', title: 'Descuento', ab: 'DE' });
    

    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>(
        document.querySelector('.sidebar .sidebar-wrapper')
      );
      this.ps = new PerfectScrollbar(elemSidebar);
    }
   this.authService.getLogo().subscribe((res: Blob) => this.authService.userLogo = res);

    console.log(this.authService.userLogo);


  }
  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
      navigator.platform.toUpperCase().indexOf('IPAD') >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  cerrarSesion() {
    this.authService.logOut();
  }

  getUserLogo(): string {
    return this.authService.getUserLogo();
  }

  editProfile() {
    this.router.navigate(['/pages/user']);
  }

}
