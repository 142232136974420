import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "../app/services/auth.service";
import { query } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if (this,this.authService.loggedIn()){
      return true;
    }
    this.router.navigate(['/login'], {queryParams: {returnUrl : state.url}});
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ClientGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if (this,(this.authService.hasPermisos({categoria: "CLIENTE"}))) return true;
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UsuarioGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if (this,(this.authService.hasPermisos({categoria: "USUARIO"}))) return true;  
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class RolGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if (this,(this.authService.hasPermisos({categoria: "ROL"}))) return true;  
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class PermisoGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if (this,(this.authService.hasPermisos({categoria: "PERMISO"}))) return true; 
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProductoGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if (this,(this.authService.hasPermisos({categoria: "PRODUCTO"}))) return true;  
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class VentaGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if (this,(this.authService.hasPermisos({categoria: "VENTA"}))) return true; 
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class DeudaGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if (this,(this.authService.hasPermisos({categoria: "DEUDA"}))) return true;  
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class DeudaDetalleGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if (this,(this.authService.hasPermisos({categoria: "DETALLEDEUDA"}))) return true;  
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ServicioGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if (this,(this.authService.hasPermisos({categoria: "SERVICIO"}))) return true;  
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class DescuentoGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if (this,(this.authService.hasPermisos({categoria: "DESCUENTO"}))) return true;  
    return false;
  }
}


@Injectable({
  providedIn: 'root'
})
export class FacturaGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if (this,(this.authService.hasPermisos({categoria: "FACTURA"}))) return true;  
    return false;
  }
}


@Injectable({
  providedIn: 'root'
})
export class FacturaDetalleGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if (this,(this.authService.hasPermisos({categoria: "DETALLEFACTURA"}))) return true;  
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SucursalGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if (this,(this.authService.hasPermisos({categoria: "SUCURSAL"}))) return true;  
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class VendedorGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if (this,(this.authService.hasPermisos({categoria: "VENDEDOR"}))) return true;  
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class EmpresaGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    if (this,(this.authService.hasPermisos({categoria: "EMPRESA"}))) return true;  
    return false;
  }
}