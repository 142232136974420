import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
//import * as JWT from 'jwt-decode';
import { JwtHelperService }  from '@auth0/angular-jwt';
import { Usuario } from 'src/app/models/usuario';

declare var $: any;

@Component({
  selector: 'app-login-cmp',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
  user = {
    userName: '',
    password: '',
  };
  
  loading = false;
  
  error: any;

  test: Date = new Date();
  private toggleButton: any;
  private sidebarVisible: boolean;
  private nativeElement: Node;
  private usuario: Usuario;
  private returnUrl: String;

  constructor(
    private element: ElementRef,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    // redirect to home if already logged in
    if (this.authService.loggedIn) { 
      this.router.navigate(['/dashboard']);
    }

    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.authService.currentUser = {} as Usuario;
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
    body.classList.add('off-canvas-sidebar');
    const card = document.getElementsByClassName('card')[0];
    setTimeout(function () {
      // after 1000 ms we add the class animated to the login/register card
      card.classList.remove('card-hidden');
    }, 700);   

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  signIn() {
    this.loading = true;
    this.authService.signIn(this.user).subscribe(
      (res) => { 
          
        localStorage.setItem('access_token', res.JWT);  
       
        if(this.authService.loggedIn()){
          //publish currentUser
          //this.authService.next_currentUserSubject(this.authService.currentUser);
        
          //const helper = new JwtHelperService();
          //const tokenDecoded = helper.decodeToken(res.JWT);

          //const expirationDate = helper.getTokenExpirationDate(res.JWT);
          //const isExpired = helper.isTokenExpired(res.JWT);

    
          this.router.navigate(['/dashboard']); 
        }       
        else{
          console.log("Usuario no esta logueado!"); 
          this.error = "Sesion expirada!";
          this.loading = false;
        }
        
      },
      (err) => {
        this.error = err.error.mensaje;
        this.loading = false;
        console.log(err);
      }
    );
  }

  sidebarToggle() {
    var toggleButton = this.toggleButton;
    var body = document.getElementsByTagName('body')[0];
    var sidebar = document.getElementsByClassName('navbar-collapse')[0];
    if (this.sidebarVisible == false) {
      setTimeout(function () {
        toggleButton.classList.add('toggled');
      }, 500);
      body.classList.add('nav-open');
      this.sidebarVisible = true;
    } else {
      this.toggleButton.classList.remove('toggled');
      this.sidebarVisible = false;
      body.classList.remove('nav-open');
    }
  }
  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');
    body.classList.remove('off-canvas-sidebar');
  }
}
