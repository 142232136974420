import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpUserEvent, HttpEvent } from "@angular/common/http";
import { AuthService } from "../services/auth.service";
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}
  
  //intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  intercept(req, next) {  
    if(!this.authService.getToken() || this.authService.getToken() == null) this.router.navigate(["/login"]); 
    const tokenizeReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.getToken()}`
      }
    })
    //console.log(tokenizeReq);
    return next.handle(tokenizeReq);
  }
  
}

