import { Injectable } from '@angular/core';
import { SERVICE_REST } from '../commons/app-utils';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {PaginationService} from './pagination.service';

import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import {Pagination} from '../models/pagination';

declare var qz: any;


@Injectable({
  providedIn: 'root'
})
export class UserService extends PaginationService {

  constructor(http: HttpClient) {
    super(http, SERVICE_REST + 'usuario');
  }
  getUsuario(): Observable<any> {
    return this.getAllPagination(new Pagination(1, 0, null, null, null));
  }

  getUsuarioPorId(id: number): Observable<any> {
    return this.http.get(this.url + '/' + id);
  }

  getUsuarioPorNombre(nombre: string): Observable<any> {
    return this.http.get(this.url + '/getByUserName?username=' + nombre);
  }

  agregarUsuario(usuario: any): Observable<any> {
    return this.http.post(this.url, usuario);
  }

  modificarUsuario(usuario: any): Observable<any> {
    return this.http.put(this.url, usuario);
  }

  eliminarUsuario(usuarioId: number): Observable<any> {
    return this.http.delete(this.url + '/' + usuarioId);
  }

  asignarSucursal(usuario: any, sucursales: any): Observable<any> {
    return this.http.put(this.url + '/sucursal/' + usuario.usuarioId, sucursales);
  }

  asignarRol(usuario: any, rol: any): Observable<any> {
    return this.http.put(this.url + '/rol/' + usuario.usuarioId, rol);
  }

  getPrinter(printerName: string): Observable<string> {
    return Observable
      .fromPromise(
        qz.websocket.connect()
          .then(() => qz.printers.find(printerName))
      )
      .map((printer: string) => printer);
      //.catch(this.errorHandler);
  }
  printData(printer: string, data: any): Observable<any> {
    const config = qz.configs.create(printer);  
    return Observable.fromPromise(qz.print(config, data))
      .map((anything: any) => anything);
      //.catch(this.errorHandler);
  }
}
