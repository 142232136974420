import { Injectable } from '@angular/core';
import { SERVICE_REST } from '../commons/app-utils';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { LoginComponent } from '../administracion/login/login.component';
import { Router } from "@angular/router";
import { Usuario } from '../models/usuario';
import { JwtHelperService }  from '@auth0/angular-jwt';
import { PermRep } from '../models/permiso';
import { Role } from '../models/rol';

const AUTH_API = SERVICE_REST + 'auth';
const helper = new JwtHelperService(); 

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {  
  currentUser = {} as Usuario;
  userLogo: any;

  constructor(private http: HttpClient, private router: Router) {}
  
  signIn(credentials): Observable<any> {
    return this.http.post(AUTH_API, {
      userName: credentials.userName,
      password: credentials.password
    }, httpOptions);
  }

  signInori(user) {
    return this.http.post<any>(AUTH_API, user);
  }


  getLogo(): Observable<Blob> {
    return this.http.get(this.getUserLogo(), { responseType: 'blob' });
  }

  loggedIn(){

    if(!this.getToken() || this.isExpired()) return false;  
     
    this.currentUser = helper.decodeToken(this.getToken())["user"];
    this.currentUser.token = this.getToken();
      
    return !!this.currentUser;
     
  }

  getToken() {
    return localStorage.getItem('access_token');
  }

  isExpired(){
    return helper.isTokenExpired(this.getToken());
  }

  logOut(): Observable<any> {  
    return this.http.delete(AUTH_API);   
  }

  loggedOut(){
    localStorage.removeItem('access_token');
    this.router.navigate(['/login']);
  }

  get isAdmin() {
    return this.currentUser && this.currentUser.roles[0].nombre.toLowerCase() === Role.Admin;
  }

  hasPermisos(perm: any): Boolean{
    if(this.currentUser===undefined) return false;
    let curPerm = new PermRep();
    curPerm = perm;
    let has : any;
        
    if(curPerm.operation && curPerm.categoria && curPerm.subcategoria)
      has = this.currentUser.roles[0].permisos.filter(item => item.nombre.toUpperCase().includes(curPerm.operation+"_"+curPerm.categoria+"_"+curPerm.subcategoria));
      
    else if(curPerm.operation && curPerm.categoria)
      has = this.currentUser.roles[0].permisos.filter(item => item.nombre.toUpperCase().includes(curPerm.operation+"_"+curPerm.categoria));
      
    else if(curPerm.categoria)
      has = this.currentUser.roles[0].permisos.filter(item => item.nombre.toUpperCase().split('_')[1] === curPerm.categoria);
                                   
    else
      has = [];
      
    return (has.length > 0);
  }

  getUserLogo(): string {
    let logoName;
    if (this.currentUser.sucursales[0]) {
      logoName = this.currentUser.sucursales[0].empresaId.logo;
    }
    return './assets/img/logos/' + (logoName ?  logoName : 'avatar.png');
  }



}
