import { HttpParams, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Pagination} from '../models/pagination';

export class PaginationService {
    constructor(protected http: HttpClient, protected url: string) {}

    getAllPagination(pag: Pagination): Observable<any> {
        return this.http.get(this.url, {params: this.getHttParamsPag(pag)});
    }

    getHttParamsPag(pag: Pagination): HttpParams {
        console.log(pag);
        let httpParams = new HttpParams()
            .set('pageNumber', pag.pageNumber.toString())
            .set('pageSize', pag.pageSize.toString());
        if (pag.filters) {
            httpParams = httpParams.set('filters', pag.filters);
        }
        if (pag.field) {
            httpParams = httpParams.set('field', pag.field);
        }
        if (pag.direction) {
            httpParams = httpParams.set('direction', pag.direction);
        }
        return httpParams;
    }
}
