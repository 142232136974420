export class Pagination {
    pageNumber: number;
    pageSize: number;
    field: string;
    direction: string;
    filters: string;

    constructor(pageNumber: number, pageSize: number, field: string, direction: string, filters: string) {
        this.pageNumber = pageNumber;
        this.pageSize = pageSize;
        this.field = field;
        this.direction = direction;
        this.filters = filters;
    }
}
